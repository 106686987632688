// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tefal-pioneer {
  display: grid;
  grid-template-columns: repeat(11, 1fr);
}

.tefal-pioneer .item-1 {
  grid-column: 1/12;
  grid-row: 1/2;
}
.tefal-pioneer .item-2 {
  grid-column: 1/7;
  grid-row: 2/5;
}
.tefal-pioneer .item-3 {
  grid-column: 7/12;
  grid-row: 2/4;
}
.tefal-pioneer .item-4 {
  grid-column: 7/12;
  grid-row: 4/5;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/TefalPioneer/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,aAAa;AACf","sourcesContent":[".tefal-pioneer {\n  display: grid;\n  grid-template-columns: repeat(11, 1fr);\n}\n\n.tefal-pioneer .item-1 {\n  grid-column: 1/12;\n  grid-row: 1/2;\n}\n.tefal-pioneer .item-2 {\n  grid-column: 1/7;\n  grid-row: 2/5;\n}\n.tefal-pioneer .item-3 {\n  grid-column: 7/12;\n  grid-row: 2/4;\n}\n.tefal-pioneer .item-4 {\n  grid-column: 7/12;\n  grid-row: 4/5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

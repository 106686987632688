import React from "react"
import "./style.css"
import veego1 from "./images/fb_cover_01.jpg"
import veego2 from "./images/AVATAR.jpg"
import veego3 from "./images/mockup_vuong09.jpg"
import veego4 from "./images/tote.jpg"
import veego5 from "./images/Layer-9.jpg"
import ImageLazy from "../../lazyImage"

function Veego() {
    return (
        <div className="veego gap">
            <div className="item-1">
                <ImageLazy src={veego1} alt="veego-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={veego2} alt="veego-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={veego3} alt="veego-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={veego4} alt="veego-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={veego5} alt="veego-5" />
            </div>
        </div>
    )
}

export default Veego

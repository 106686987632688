// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.techcombank {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.techcombank .item-1,
.techcombank .item-3 {
  grid-column: 1/2;
}
.techcombank .item-2,
.techcombank .item-4 {
  grid-column: 2/3;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/Techcombank/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;;EAEE,gBAAgB;AAClB;AACA;;EAEE,gBAAgB;AAClB","sourcesContent":[".techcombank {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}\n\n.techcombank .item-1,\n.techcombank .item-3 {\n  grid-column: 1/2;\n}\n.techcombank .item-2,\n.techcombank .item-4 {\n  grid-column: 2/3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

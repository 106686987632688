import React from "react"
import "./style.css"
import tefal1 from "./images/Vector-Smart-Object.jpg"
import tefal2 from "./images/MB_Eiffel16.9.jpg"
import tefal3 from "./images/aebd0f152493753.631f6199856e0.jpg"
import tefal4 from "./images/KV_Idea2.jpg"
import tefal5 from "./images/image-049.jpg"
import ImageLazy from "../../lazyImage"

function TefalProduct() {
    return (
        <div className="tefal-product gap">
            <div className="item-1">
                <ImageLazy src={tefal1} alt="tefal1" />
            </div>
            <div className="item-2">
                <ImageLazy src={tefal2} alt="tefal2" />
            </div>
            <div className="item-3">
                <ImageLazy src={tefal3} alt="tefal3" />
            </div>
            <div className="item-4">
                <ImageLazy src={tefal4} alt="tefal4" />
            </div>

            <div className="item-5">
                <ImageLazy src={tefal5} alt="tefal5" />
            </div>
        </div>
    )
}

export default TefalProduct

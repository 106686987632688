import React from "react"
import "./style.css"
import techcombank1 from "./images/twoimage.jpg"
import techcombank2 from "./images/post-4_opt2.jpg"
import techcombank3 from "./images/post-3.jpg"
import techcombank4 from "./images/post-5.jpg"
import ImageLazy from "../../lazyImage"
function Techcombank() {
    return (
        <div className="techcombank gap">
            <div className="item-1">
                <ImageLazy src={techcombank1} alt="techcombank-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={techcombank2} alt="techcombank-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={techcombank3} alt="techcombank-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={techcombank4} alt="techcombank-4" />
            </div>
        </div>
    )
}

export default Techcombank

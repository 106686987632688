import React, { useState } from "react";
import "./style.css"
import useViewport from "../../../hooks/useViewport";
import ImageLazy from "../../lazyImage"
import ReactPlayer from 'react-player/lazy'
import videoCovid from "./images/Motion_Covid_60.mp4";
import covid1 from "./images/BUNG-BINH.jpg"
import covid2 from "./images/Fuel-Lockdown-ending-ANIM.jpg"
import covid3 from "./images/Be-quiet-I-ll-tell-you-hear.jpg"
import covid4 from "./images/lam-dep-sau-co-dzich.jpg"
import covid5 from "./images/chup-anh-cuoi-video.jpg"
import covid6 from "./images/Bun-Bo-Motion.jpg"
import covid7 from "./images/Tra-sua-Animation.jpg"
import covid8 from "./images/nhau_motion.jpg"
import covid9 from "./images/final.jpg"
import covid10 from "./images/Homecoming-Lockdown-ending-ANIM.jpg"
import covid11 from "./images/cba.jpg"

function Covid() {
    const [play, setPlay] = useState(false)

    const viewPort = useViewport();
    const isMobile = viewPort.width < 1280;

    function handlerClickPlay(e) {
        e.preventDefault();
        setPlay(prev => !prev)
    }

    return (
        <>
            <div className="video" onClick={handlerClickPlay}>
                <ReactPlayer
                    playing={play}
                    className='react-player'
                    url={videoCovid}
                    width='100%'
                    height='100%'
                    controls={true}
                />
                {
                    !isMobile && <>
                        {!play && <div className="overlay-video"></div>}
                        {!play &&
                            <div className="play-video">
                                <i className="d-none bi bi-pause-circle icon-pause"></i>
                                <i className="bi bi-play-circle icon-play"></i>
                            </div>
                        }
                    </>
                }
            </div>

            <div className="covid-content">
                <p>We want to do right now:</p>
                <p>Fill up the tank, walk around the city - Get a haircut - Take care beauty - Organize a wedding - Eating Bun Bo - Drinking milk tea - Hang out - Walking on Nguyen Hue street - Meet homies - Enjoy "traffic jam"</p>
            </div>

            <div className="covid-grid gap">
                <div className="item">
                    <ImageLazy src={covid1} alt="covid-1" />
                </div>
                <div className="item">
                    <ImageLazy src={covid2} alt="covid-2" />
                </div>
                <div className="item">
                    <ImageLazy src={covid3} alt="covid-3" />
                </div>
                <div className="item">
                    <ImageLazy src={covid4} alt="covid-4" />
                </div>
                <div className="item">
                    <ImageLazy src={covid5} alt="covid-5" />
                </div>
                <div className="item">
                    <ImageLazy src={covid6} alt="covid-6" />
                </div>
                <div className="item">
                    <ImageLazy src={covid7} alt="covid-7" />
                </div>
                <div className="item">
                    <ImageLazy src={covid8} alt="covid-8" />
                </div>
                <div className="item">
                    <ImageLazy src={covid9} alt="covid-9" />
                </div>
                <div className="item">
                    <ImageLazy src={covid10} alt="covid-10" />
                </div>
                <div className="item">
                    <ImageLazy src={covid11} alt="covid-11" />
                </div>
            </div>
        </>
    )
}

export default Covid

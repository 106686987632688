import React from "react"
import "./style.css"
import clothes1 from "./images/CB_cover_t5.jpg"
import clothes2 from "./images/PREVIEW_CB_cam.jpg"
import clothes3 from "./images/PREVIEW_WHITE.jpg"
import clothes4 from "./images/CB_thang2_cover.jpg"
import clothes5 from "./images/Seri_Hong_Preview.jpg"
import clothes6 from "./images/PREVIEW_CB.jpg"
import clothes7 from "./images/CB_thang2_1402_preview.jpg"
import ImageLazy from "../../lazyImage"

function Clothes() {
    return (
        <div className="clothes gap">
            <div className="item-1">
                <ImageLazy src={clothes1} alt="clothes1" />
            </div>
            <div className="item-2">
                <ImageLazy src={clothes2} alt="clothes2" />
            </div>
            <div className="item-3">
                <ImageLazy src={clothes3} alt="clothes3" />
            </div>
            <div className="item-4">
                <ImageLazy src={clothes4} alt="clothes4" />
            </div>
            <div className="item-5">
                <ImageLazy src={clothes5} alt="clothes5" />
            </div>
            <div className="item-6">
                <ImageLazy src={clothes6} alt="clothes6" />
            </div>
            <div className="item-7">
                <ImageLazy src={clothes7} alt="clothes7" />
            </div>
        </div>
    )
}

export default Clothes

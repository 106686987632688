import React from "react"
import "./style.css"
import nampool1 from "./images/MScupdraw_color2.jpg"
import nampool2 from "./images/MScupdraw_color1.jpg"
import nampool3 from "./images/Layer-5.jpg"
import nampool4 from "./images/Layer-4.jpg"
import nampool5 from "./images/Layer-6.jpg"
import nampool6 from "./images/Untitled_Artwork-51.jpg"
import nampool7 from "./images/Layer-8.jpg"
import nampool8 from "./images/Layer-7.jpg"
import ImageLazy from "../../lazyImage"
function Nampool() {
    return (
        <div className="nampool gap">
            <div className="item-1">
                <ImageLazy src={nampool1} alt="nampool-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={nampool2} alt="nampool-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={nampool3} alt="nampool-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={nampool4} alt="nampool-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={nampool5} alt="nampool-5" />
            </div>
            <div className="item-6">
                <ImageLazy src={nampool6} alt="nampool-6" />
            </div>
            <div className="item-7">
                <ImageLazy src={nampool7} alt="nampool-7" />
            </div>
            <div className="item-8">
                <ImageLazy src={nampool8} alt="nampool-8" />
            </div>
        </div>
    )
}

export default Nampool

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cocktail {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
`, "",{"version":3,"sources":["webpack://./src/components/details/ChillCocktail/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC","sourcesContent":[".cocktail {\n  display: grid;\n  grid-template-columns: repeat(1, 1fr);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

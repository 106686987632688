import React from "react"
import person from "../assets/images/about/layer.png"
import combineMobile from "../assets/images/about/mobi.png"
import combineDesktop from "../assets/images/about/combine.png"
import useViewport from '../hooks/useViewport';
import ImageLazy from "../components/lazyImage"
import "./about.css"

function About() {
    const viewPort = useViewport();
    const isMobile = viewPort.width < 768;

    return (
        <>
            <div className="wrapper">
                <div className="container about_me">

                    <div className="about_me-top">
                        <p>Welcome to my
                            <span className="animated">
                                <span>gallery</span>
                                <span>porfolio</span>
                                <span>art</span>
                                <span>style</span>
                                <span>town</span>
                            </span>
                        </p>
                        <p>My name is Le Anh Khoa. Thank you for taking the time to see me. I am a graphic designer with nearly 5 years of experience in the advertising industry in Ho Chi Minh City.</p>
                        <p>Because I am passionate about new things, out of work, I am always creative in daily hobbies to make life more interesting and colorful.</p>
                    </div>


                    <div className="about_me-middle">
                        <ImageLazy
                            src={person}
                            alt="about-me"
                        />
                    </div>

                    <div className="about_me-bottom">
                        <p><span>2019</span> - Intern Designer at The Dot Agency</p>
                        <p><span>2020</span> - Junior Graphic Designer at GWS Agency</p>
                        <p><span>2021</span> - Junior Graphic Designer at The Locals Agency</p>
                        <p><span>2022</span> - Senior Graphic Designer at The Locals Agency</p>
                        <p><span>2023</span> - Graphic Designer at Mango Digital</p>
                    </div>
                </div>
            </div>
            <div className="image-combine">
                <ImageLazy
                    src={isMobile ? combineMobile : combineDesktop}
                    alt="combine"
                />
            </div>
        </>
    )
}

export default About

import MB_Eiffel16 from "../assets/images/work/MB_Eiffel16.9.jpg"
import mockup_vuong04_02 from "../assets/images/work/mockup_vuong04_02.jpg"
import Free_Display_Stand_Mockup_2 from "../assets/images/work/Free_Display_Stand_Mockup_2.jpg"
import Mockup233 from "../assets/images/work/Mockup233.jpg"
import cover2234 from "../assets/images/work/cover2234.jpg"
import mockup_vuong0 from "../assets/images/work/mockup_vuong0.jpg"
import Clothes_Bar2 from "../assets/images/work/Clothes-Bar2.jpg"
import mockup_vuong7 from "../assets/images/work/mockup_vuong7.jpg"
import mockup_doc23 from "../assets/images/work/mockup_doc23.jpg"
import mockup_vuong025 from "../assets/images/work/mockup_vuong025.jpg"
import xtraa_nampool from "../assets/images/work/xtraa_nampool.jpg"
import mockup_vuong9 from "../assets/images/work/mockup_vuong9.jpg"
import mock022 from "../assets/images/work/022.jpg"
import mockup_vuong2 from "../assets/images/work/mockup_vuong2.jpg"
import mockup_doc04 from "../assets/images/work/mockup_doc04.jpg"
import leekumkee from "../assets/images/work/leekumkee.jpg"
import Cover_Lockdown_ending_ANIM2 from "../assets/images/work/Cover-Lockdown-ending-ANIM2.jpg"
import TC_Trung_Thu2 from "../assets/images/work/TC-Trung-Thu2.jpg"
import TefalProduct from "../components/details/TefalProduct/TefalProduct"
import TefalPioneer from "../components/details/TefalPioneer/TefalPioneer"
import Lacalut from "../components/details/Lacalut/Lacalut"
import Techcombank from "../components/details/Techcombank/Techcombank"
import ZoneV from "../components/details/ZoneV/ZoneV"
import Nampool from "../components/details/Nampool/Nampool"
import Clothes from "../components/details/Clothes/Clothes"
import Plt from "../components/details/PLT/PLT"
import Rawit from "../components/details/Rawit/Rawit"
import Vincom from "../components/details/Vincom/Vincom"
import Ayamnya from "../components/details/Ayamnya/Ayamnya"
import Veego from "../components/details/Veego/Veego"
import GreenFeed from "../components/details/GreenFeed/GreenFeed"
import ChillCocktail from "../components/details/ChillCocktail/ChillCocktail"
import Vodka from "../components/details/Vodka/Vodka"
import LeeKumKee from "../components/details/LeeKumKee/LeeKumKee"
import Covid from "../components/details/Covid/Covid"
import MidAutumn from "../components/details/MidAutumn/MidAutumn"

export const data = [
  {
    "id": 1,
    "image": MB_Eiffel16,
    "title": ["TEFAL PRODUCT", "KEY VISUAL"],
    "insight": {
      "title": ["TEFAL PRODUCT", "KEY VISUAL"],
      "description": "Before releasing a finished product, I often do draft ideas to choose the most suitable one, and this is the draft idea for key visual. I make ideas and use techniques to create natural, luxurious visuals and response the brand's orientation \"luxury home technology\"",
      "client": "Client: Tefal - Agency: The Locals",
      "next": "TEFAL PIONEER KEY VISUAL",
      "slugNext": "TEFAL PIONEER KEY VISUAL",
      "comp": <TefalProduct />
    }
  },
  {
    "id": 2,
    "image": mockup_vuong04_02,
    "title": ["TEFAL PIONEER", "KEY VISUAL"],
    "insight": {
      "title": ["TEFAL PIONEER", "KEY VISUAL"],
      "description": "Tefal positions as a pioneer in the field of kitchenware and household stuff. The brand wants to convey the message that Tefal not only helps you with daily household chores, but also a decor product that makes your home more luxurious. We made it clear at the key visual.",
      "client": "Client: Tefal - Agency: The Locals",
      "next": "LACALUT IDEA KEY VISUAL",
      "slugNext": "LACALUT IDEA KEY VISUAL",
      "comp": <TefalPioneer />
    }
  },
  {
    "id": 3,
    "image": Free_Display_Stand_Mockup_2,
    "title": ["LACALUT IDEA", "KEY VISUAL"],
    "insight": {
      "title": ["LACALUT IDEA", "KEY VISUAL"],
      "description": "The personal tasks with the key message \"The stronger protect the longer it lasts\" was created exclusively for Laculut brand - German toothpaste. The purpose of the task is to practice Critical Thinking and build Concept Ideas.",
      "client": "Personal Project",
      "comp": <Lacalut />,
      "next": "TECHCOMBANK SOCIAL POST",
      "slugNext": "TECHCOMBANK SOCIAL POST",
    }
  },
  {
    "id": 4,
    "image": Mockup233,
    "title": ["TECHCOMBANK", "SOCIAL POST"],
    "insight": {
      "title": ["TECHCOMBANK", "SOCIAL POST"],
      "description": "Techcombank is one of the largest and most prestigious banks in Vietnam. Linking thousands of domestic and foreign customers. These are designs for promotional posts on fanpage about the benefits of connecting with Techcombank.",
      "client": "Client: Techcombank - Personal",
      "comp": <Techcombank />,
      "next": "ZONE V SOCIAL POST",
      "slugNext": "ZONE V SOCIAL POST",
    }
  },
  {
    "id": 5,
    "image": cover2234,
    "title": ["ZONE V", "SOCIAL POST"],
    "insight": {
      "title": ["ZONE V", "SOCIAL POST"],
      "description": "Zone V is the brainchild of a Vietnamese artist, with the desire to bring the unique beauty of Pouring Paint closer to everyone. Each products is unique, because they are only made based on the needs and personality of the owner. Capturing the unique trend of this line of paintings, we combine together, once again bringing unique top-notch art to everyone.",
      "client": "Client: Zone V - Agency: GWS",
      "comp": <ZoneV />,
      "next": "XTRAA NAMPOOL PACKAGING",
      "slugNext": "XTRAA NAMPOOL PACKAGING",
    }
  },
  {
    "id": 6,
    "image": mockup_vuong0,
    "title": ["XTRAA NAMPOOL", "PACKAGING"],
    "insight": {
      "title": ["XTRAA NAMPOOL", "ARTWORK PACKAGING"],
      "description": "Xtraa Nampool is a convenient cup noodle with 2 main flavors: Rasa Laksa & Rasa Ayam. Be attractive, the toppings on the packaging are all hand-painted in the style of dynamic that showcase young people iconic nature, heroing the brand wordmark.",
      "client": "Client: Mie Seedaap - Agency: The Locals",
      "comp": <Nampool />,
      "next": "CLOTHES BAR SOCIAL POST",
      "slugNext": "CLOTHES BAR SOCIAL POST",
    }
  },
  {
    "id": 7,
    "image": Clothes_Bar2,
    "title": ["CLOTHES BAR", "SOCIAL POST"],
    "insight": {
      "title": ["CLOTHES BAR", "SOCIAL POST"],
      "description": "To raise brand awareness, CLOTHES BAR, a trendy women's clothing store, utilizing the Facebook platform as its primary channel. Therefore, the displayed content has to be attractive, eye-catching, and illustrate the brand's personality.",
      "client": "Client: Clothes Bar - Agency: Content 500k",
      "comp": <Clothes />,
      "next": "PHUOC LOC THINH BRANDING",
      "slugNext": "PHUOC LOC THINH BRANDING",
    }
  },
  {
    "id": 8,
    "image": mockup_vuong7,
    "title": ["PHUOC LOC THINH", "BRANDING"],
    "insight": {
      "title": ["PHUOC LOC THINH", "BACKDROP - PACKAGING - FLYER"],
      "description": "To impress and attract customers at the rice event in Thailand in 2022, we have conceptualized and designed the Backdrop, Packaging & Flyer for Phuoc Loc Thinh - a brand specializing in rice distribution.",
      "client": "Client: Phuoc Loc Thinh - Agency: The Locals",
      "comp": <Plt />,
      "next": "RAWIT BINGIT KEY VISUAL",
      "slugNext": "RAWIT BINGIT KEY VISUAL",
    }
  },
  {
    "id": 9,
    "image": mockup_doc23,
    "title": ["RAWIT BINGIT", "KEY VISUAL"],
    "insight": {
      "title": ["RAWIT BINGIT", "KEY VISUAL"],
      "description": "During the launch of Rawit Bingit, Mie Seedaap wanted to emphasize an explosive spicy taste. In the idea and implementation, we focus on colors and clever use of elements to show the characteristics and attract the viewers.",
      "client": "Client: Mie Seedaap - Agency: The Locals",
      "comp": <Rawit />,
      "next": "VINCOM GRAND OPENING",
      "slugNext": "VINCOM GRAND OPENING KEY VISUAL",
    }
  },
  {
    "id": 10,
    "image": mockup_vuong025,
    "title": ["VINCOM", "GRAND OPENING", "KEY VISUAL"],
    "insight": {
      "title": ["VINCOM GRAND OPENING", "KEY VISUAL"],
      "description": "Based on our client's basic image source, we have used many specialized techniques to make the design more vivid and eye-catching. The image is used as the official key visual during the launch of Vincom in Hanoi.",
      "client": "Client: Vincom - Agency: The Locals",
      "comp": <Vincom />,
      "next": "NAMPOOL AYAMNYA KEY VISUAL",
      "slugNext": "NAMPOOL AYAMNYA KEY VISUAL",
    }
  },
  {
    "id": 11,
    "image": xtraa_nampool,
    "title": ["NAMPOOL", "AYAMNYA", "KEY VISUAL"],
    "insight": {
      "title": ["NAMPOOL AYAMNYA", "KEY VISUAL"],
      "description": "Inspired by the insight of the target customer group - dynamic young people, we decided to choose 3D gaming and graphic pixel styles to implement for Key Visual during the period Mie Seedaap launched Nampool Ayamnya noodles.",
      "client": "Client: Mie Seedaap - Agency: The Locals",
      "comp": <Ayamnya />,
      "next": "VEEGO BRANDING",
      "slugNext": "VEEGO BRANDING",
    }
  },
  {
    "id": 12,
    "image": mockup_vuong9,
    "title": ["VEEGO", "BRANDING"],
    "insight": {
      "title": ["VEEGO", "BRANDING"],
      "description": "VEEGO is a new brand in the tourism industry, so to increase customer's brand awareness, we have conceptualized and designed the concept for the brand identity set.",
      "client": "Client: Veego - Agency: GWS",
      "comp": <Veego />,
      "next": "GREEN FEED FACTORY",
      "slugNext": "GREEN FEED SHOOTING",
    }
  },
  {
    "id": 13,
    "image": mock022,
    "title": ["GREEN FEED", "SHOOTING"],
    "insight": {
      "title": ["GREEN FEED", "SHOOTING FACTORY"],
      "description": "To renew the existing photo collection, Green Feed captured the entire factory area with bright colors. Moreover, to have the best and most beautiful products.",
      "client": "Client: Green Feed - Agency: The Locals",
      "comp": <GreenFeed />,
      "next": "CHILL COCKTAIL Key Visual",
      "slugNext": "CHILL COCKTAIL Key Visual",
    }
  },
  {
    "id": 14,
    "image": mockup_vuong2,
    "title": ["CHILL COCKTAIL", "KEY VISUAL"],
    "insight": {
      "title": ["CHILL COCKTAIL", "KEY VISUAL"],
      "description": "In a launching phase in the Vietnamese market, Chill Cocktail utilized a bold, graphic, and visually disruptive campaign to stimulate tantalizing the senses, driving temptation and desirability of the target audience with the message \"Bật Chill Cocktail chuẩn gu đúng điệu\"",
      "client": "Client: Chill Cocktail - Agency: The Locals",
      "comp": <ChillCocktail />,
      "next": "seagram vodka",
      "slugNext": "seagram vodka flyer and poster",
    }
  },
  {
    "id": 15,
    "image": mockup_doc04,
    "title": ["SEAGRAM VODKA", "FLYER", "& POSTER"],
    "insight": {
      "title": ["SEAGRAM VODKA", "FLYER & POSTER"],
      "description": "Inspired by smooth taste of Seagram’s Extra Smooth Vodka, \"Lên mượt, đáp êm\" was chosen as the key message. To increase brand awareness and attraction, we designed flyers and posters for this project.",
      "client": "Client: Seagram Vodka - Agency: GWS",
      "comp": <Vodka />,
      "next": "LEE KUM KEE SOCIAL POST",
      "slugNext": "LEE KUM KEE SOCIAL POST",
    }
  },
  {
    "id": 16,
    "image": leekumkee,
    "title": ["LEE KUM KEE", "SOCIAL POST"],
    "insight": {
      "title": ["LEE KUM KEE", "SOCIAL POST"],
      "description": "To reach more customers in the Vietnam market, LEE KUM KEE - a Hong Kong sauce brand continues its communication plan with the message \"Gia vị nền cho mọi món ngon\" on Facebook. The message is highlighted with the copy and visual close to Vietnamese culture, and suitable for housewives.",
      "client": "Client: Lee Kum Kee - Agency: GWS",
      "comp": <LeeKumKee />,
      "next": "THE LOCALS PROJECT COVID",
      "slugNext": "THE LOCALS PROJECT COVID",
    }
  },
  {
    "id": 17,
    "image": Cover_Lockdown_ending_ANIM2,
    "title": ["THE LOCALS", "PROJECT COVID"],
    "insight": {
      "title": ["THE LOCALS", "PROJECT COVID"],
      "description": "During the Covid-19 outbreak, everyone is at home, and so are we. Routine activities have also become more special, and we all want to do immediately after the regulation ends. These dreams were talked via the interesting images below. One of the impressive projects.",
      "client": "Agency: The Locals",
      "comp": <Covid />,
      "next": "THE LOCALS MID AUTUMN",
      "slugNext": "THE LOCALS MID AUTUMN",
    }
  },
  {
    "id": 18,
    "image": TC_Trung_Thu2,
    "title": ["THE LOCALS", "MID AUTUMN"],
    "insight": {
      "title": ["THE LOCALS", "MID AUTUMN"],
      "description": "To create \"fun\" and improve the skills of the members, The Locals always organize small exercises after hard work. Mid-Autumn Project is one of the internal projects to save the memories of Mid-Autumn Festival 2022. Based on the outstanding mid-autumn songs, we have refreshed the lyrics in a more trendy and catchy way.",
      "client": "Agency: The Locals",
      "comp": <MidAutumn />,
      "next": "TEFAL PRODUCT KEY VISUAL",
      "slugNext": "TEFAL PRODUCT KEY VISUAL"
    }
  }
]

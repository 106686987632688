import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import RootLayout from "./pages/RootLayout";
import Work from "./pages/Work";
import About from "./pages/About";
import WorkDetail from "./pages/WorkDetail";
import Contact from './pages/Contact';
import NotFound from './pages/NotFound';

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    children: [
      { index: true, element: <Work /> },
      { path: "/work/:slug", element: <WorkDetail /> },
      { path: "/about", element: <About /> },
    ]
  },
  { path: "/contact", element: <Contact /> },
  { path: "*", element: <NotFound /> },
])

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;

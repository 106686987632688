// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found {
  display: block;
  text-align: center;
  margin-top: 5%;
}
.four1 {
  animation: swing ease-in-out 1.5s infinite alternate;
  display: inline-block;
}
.zero {
  animation: swing2 ease-in-out 1.5s infinite alternate;
  display: inline-block;
}
.four2 {
  animation: swing3 ease-in-out 1.5s infinite alternate;
  display: inline-block;
}

@keyframes swing {
  0% {
    transform: rotate(3deg);
  }
  100% {
    transform: rotate(-3deg);
  }
}

@keyframes swing2 {
  0% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(3deg);
  }
}
@keyframes swing3 {
  0% {
    transform: rotate(-3deg);
  }
  100% {
    transform: rotate(8deg);
  }
}
.not-found h1 {
  text-align: center;
  color: var(--color-nav-link);
}
.back-home {
  text-align: center;
  margin-top: 5rem;
}
.back-home a {
  font-size: var(--fs-2);
  text-decoration: none;
}
.back-home i {
  margin-right: 0.5rem;
}

@media screen and (min-width: 1280px) {
  .back-home a {
    font-size: var(--fs-4);
    transition: 0.3s opacity;
  }
  .back-home a:hover {
    opacity: 0.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/not-found.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,cAAc;AAChB;AACA;EACE,oDAAoD;EACpD,qBAAqB;AACvB;AACA;EACE,qDAAqD;EACrD,qBAAqB;AACvB;AACA;EACE,qDAAqD;EACrD,qBAAqB;AACvB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,wBAAwB;EAC1B;AACF;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,uBAAuB;EACzB;AACF;AACA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,uBAAuB;EACzB;AACF;AACA;EACE,kBAAkB;EAClB,4BAA4B;AAC9B;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,sBAAsB;EACtB,qBAAqB;AACvB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,sBAAsB;IACtB,wBAAwB;EAC1B;EACA;IACE,YAAY;EACd;AACF","sourcesContent":[".not-found {\n  display: block;\n  text-align: center;\n  margin-top: 5%;\n}\n.four1 {\n  animation: swing ease-in-out 1.5s infinite alternate;\n  display: inline-block;\n}\n.zero {\n  animation: swing2 ease-in-out 1.5s infinite alternate;\n  display: inline-block;\n}\n.four2 {\n  animation: swing3 ease-in-out 1.5s infinite alternate;\n  display: inline-block;\n}\n\n@keyframes swing {\n  0% {\n    transform: rotate(3deg);\n  }\n  100% {\n    transform: rotate(-3deg);\n  }\n}\n\n@keyframes swing2 {\n  0% {\n    transform: rotate(-3deg);\n  }\n  100% {\n    transform: rotate(3deg);\n  }\n}\n@keyframes swing3 {\n  0% {\n    transform: rotate(-3deg);\n  }\n  100% {\n    transform: rotate(8deg);\n  }\n}\n.not-found h1 {\n  text-align: center;\n  color: var(--color-nav-link);\n}\n.back-home {\n  text-align: center;\n  margin-top: 5rem;\n}\n.back-home a {\n  font-size: var(--fs-2);\n  text-decoration: none;\n}\n.back-home i {\n  margin-right: 0.5rem;\n}\n\n@media screen and (min-width: 1280px) {\n  .back-home a {\n    font-size: var(--fs-4);\n    transition: 0.3s opacity;\n  }\n  .back-home a:hover {\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

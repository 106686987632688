// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lkt {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.lkt .item-1 {
  grid-column: 1/7;
}
.lkt .item-2 {
  grid-column: 1/4;
}
.lkt .item-3 {
  grid-column: 4/7;
}
.lkt .item-4 {
  grid-column: 1/3;
}
.lkt .item-5 {
  grid-column: 3/5;
}
.lkt .item-6 {
  grid-column: 5/7;
}
.lkt .item-7 {
  grid-column: 1/7;
}
.lkt .item-8 {
  grid-column: 1/3;
}
.lkt .item-9 {
  grid-column: 3/5;
}
.lkt .item-10 {
  grid-column: 5/7;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/LeeKumKee/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".lkt {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n}\n\n.lkt .item-1 {\n  grid-column: 1/7;\n}\n.lkt .item-2 {\n  grid-column: 1/4;\n}\n.lkt .item-3 {\n  grid-column: 4/7;\n}\n.lkt .item-4 {\n  grid-column: 1/3;\n}\n.lkt .item-5 {\n  grid-column: 3/5;\n}\n.lkt .item-6 {\n  grid-column: 5/7;\n}\n.lkt .item-7 {\n  grid-column: 1/7;\n}\n.lkt .item-8 {\n  grid-column: 1/3;\n}\n.lkt .item-9 {\n  grid-column: 3/5;\n}\n.lkt .item-10 {\n  grid-column: 5/7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

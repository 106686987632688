import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function ImageLazy({ src, alt, placeholder = "", placeholderSrc = "" }) {
    return (
        <LazyLoadImage
            effect="opacity"
            src={src}
            alt={alt}
            placeholder={placeholder}
            placeholderSrc={placeholderSrc}
        />
    )
}

export default ImageLazy

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.greenFeed {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.greenFeed .item-1 {
  grid-column: 1/2;
  grid-row: 1/2;
}
.greenFeed .item-2 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.greenFeed .item-3 {
  grid-column: 3/4;
  grid-row: 1/2;
}
.greenFeed .item-4 {
  grid-column: 1/2;
  grid-row: 2/3;
}
.greenFeed .item-5 {
  grid-column: 2/3;
  grid-row: 2/4;
}
.greenFeed .item-6 {
  grid-column: 3/4;
  grid-row: 2/3;
}
.greenFeed .item-7 {
  grid-column: 1/2;
  grid-row: 3/5;
}
.greenFeed .item-8 {
  grid-column: 2/3;
  grid-row: 4/5;
}
.greenFeed .item-9 {
  grid-column: 3/4;
  grid-row: 3/5;
}
.greenFeed .item-10 {
  grid-column: 1/2;
  grid-row: 5/6;
}
.greenFeed .item-11 {
  grid-column: 2/3;
  grid-row: 5/6;
}
.greenFeed .item-12 {
  grid-column: 3/4;
  grid-row: 5/6;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/GreenFeed/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".greenFeed {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n}\n\n.greenFeed .item-1 {\n  grid-column: 1/2;\n  grid-row: 1/2;\n}\n.greenFeed .item-2 {\n  grid-column: 2/3;\n  grid-row: 1/2;\n}\n.greenFeed .item-3 {\n  grid-column: 3/4;\n  grid-row: 1/2;\n}\n.greenFeed .item-4 {\n  grid-column: 1/2;\n  grid-row: 2/3;\n}\n.greenFeed .item-5 {\n  grid-column: 2/3;\n  grid-row: 2/4;\n}\n.greenFeed .item-6 {\n  grid-column: 3/4;\n  grid-row: 2/3;\n}\n.greenFeed .item-7 {\n  grid-column: 1/2;\n  grid-row: 3/5;\n}\n.greenFeed .item-8 {\n  grid-column: 2/3;\n  grid-row: 4/5;\n}\n.greenFeed .item-9 {\n  grid-column: 3/4;\n  grid-row: 3/5;\n}\n.greenFeed .item-10 {\n  grid-column: 1/2;\n  grid-row: 5/6;\n}\n.greenFeed .item-11 {\n  grid-column: 2/3;\n  grid-row: 5/6;\n}\n.greenFeed .item-12 {\n  grid-column: 3/4;\n  grid-row: 5/6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from "react"
import "./style.css"
import lkt1 from "./images/cover_t5.jpg"
import lkt2 from "./images/Post_07_preivew.jpg"
import lkt3 from "./images/Post_ngay23.jpg"
import lkt4 from "./images/Post_09_thumbnail.jpg"
import lkt5 from "./images/pagelike_optcham.jpg"
import lkt6 from "./images/Post_8.3.jpg"
import lkt7 from "./images/cover_LKK_8.3.jpg"
import lkt8 from "./images/SotNuong.jpg"
import lkt9 from "./images/minigame_preview.jpg"
import lkt10 from "./images/pagelike_optbikiep.jpg"
import ImageLazy from "../../lazyImage"


function LeeKumKee() {
    return (
        <div className="lkt gap">
            <div className="item-1">
                <ImageLazy src={lkt1} alt="lkt-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={lkt2} alt="lkt-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={lkt3} alt="lkt-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={lkt4} alt="lkt-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={lkt5} alt="lkt-5" />
            </div>
            <div className="item-6">
                <ImageLazy src={lkt6} alt="lkt-6" />
            </div>
            <div className="item-7">
                <ImageLazy src={lkt7} alt="lkt-7" />
            </div>
            <div className="item-8">
                <ImageLazy src={lkt8} alt="lkt-8" />
            </div>
            <div className="item-9">
                <ImageLazy src={lkt9} alt="lkt-9" />
            </div>
            <div className="item-10">
                <ImageLazy src={lkt10} alt="lkt-10" />
            </div>
        </div>
    )
}

export default LeeKumKee

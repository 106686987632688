import React from "react"
import "./style.css"
import tefalPioneer1 from "./images/Frame1.jpg"
import tefalPioneer2 from "./images/socialpost_opt2.jpg"
import tefalPioneer3 from "./images/Frame2_16.9.jpg"
import tefalPioneer4 from "./images/Frame4_16.9.jpg"
import ImageLazy from "../../lazyImage"
function TefalPioneer() {
    return (
        <div className="tefal-pioneer gap">
            <div className="item-1">
                <ImageLazy src={tefalPioneer1} alt="tefal-pioneer-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={tefalPioneer2} alt="tefal-pioneer-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={tefalPioneer3} alt="tefal-pioneer-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={tefalPioneer4} alt="tefal-pioneer-4" />
            </div>
        </div>
    )
}

export default TefalPioneer

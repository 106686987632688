import React from "react"
import "./style.css"
import rawit1 from "./images/KV_miesedaap_yellow.jpg"
import rawit2 from "./images/mockup_doc03.jpg"
import ImageLazy from "../../lazyImage"
function Rawit() {
    return (
        <div className="rawit gap">
            <div className="item-1">
                <ImageLazy src={rawit1} alt="rawit-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={rawit2} alt="rawit-2" />
            </div>
        </div>
    )
}

export default Rawit

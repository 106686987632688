import React from "react"
import "./style.css"
import cocktail1 from "./images/KV-CHILL-BAR-FINAL-DI.jpg"
import cocktail2 from "./images/KV-CHILL-POOLPARTY-round8-retouch_skin-copy.jpg"
import cocktail3 from "./images/KV-CHILL-ROOFTOP-Round6-copy.jpg"

import ImageLazy from "../../lazyImage"

function ChillCocktail() {
    return (
        <div className="cocktail gap">
            <div className="item-1">
                <ImageLazy src={cocktail1} alt="cocktail-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={cocktail2} alt="cocktail-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={cocktail3} alt="cocktail-3" />
            </div>
        </div>
    )
}

export default ChillCocktail

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact {
  margin-top: 7.7rem;
}

.contact_info {
  text-align: center;
  font-weight: 300;
}

.contact_info p {
  color: var(--color-desc);
  font-size: var(--fs-2);
  text-transform: capitalize;
}

.contact_info a {
  color: var(--color-desc);
  text-decoration: none;
  display: block;
  font-size: var(--fs-2);
}

.contact_info p:nth-child(2) {
  margin: 2rem 0 1.5rem;
}

.contact_info a:nth-child(1) {
  display: inline-block;
}

.contact_info img {
  width: 8.5rem;
}

.contact_image {
  max-width: 100vw;
}

.contact_image img {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1280px) {
  .contact {
    margin-top: 0;
  }

  .contact_info {
    position: absolute;
    top: 30%;
    z-index: 10;
  }

  .contact_info p:nth-child(2) {
    margin: 2rem 0;
  }

  .contact_info p {
    font-size: var(--fs-3);
  }

  .contact_info a {
    font-size: var(--fs-3);
  }

  .contact_image {
    height: 100vh;
  }

  .contact_info img {
    width: 7rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/contact.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,wBAAwB;EACxB,sBAAsB;EACtB,0BAA0B;AAC5B;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,cAAc;EACd,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;IAClB,QAAQ;IACR,WAAW;EACb;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;AACF","sourcesContent":[".contact {\n  margin-top: 7.7rem;\n}\n\n.contact_info {\n  text-align: center;\n  font-weight: 300;\n}\n\n.contact_info p {\n  color: var(--color-desc);\n  font-size: var(--fs-2);\n  text-transform: capitalize;\n}\n\n.contact_info a {\n  color: var(--color-desc);\n  text-decoration: none;\n  display: block;\n  font-size: var(--fs-2);\n}\n\n.contact_info p:nth-child(2) {\n  margin: 2rem 0 1.5rem;\n}\n\n.contact_info a:nth-child(1) {\n  display: inline-block;\n}\n\n.contact_info img {\n  width: 8.5rem;\n}\n\n.contact_image {\n  max-width: 100vw;\n}\n\n.contact_image img {\n  width: 100%;\n  height: 100%;\n}\n\n@media screen and (min-width: 1280px) {\n  .contact {\n    margin-top: 0;\n  }\n\n  .contact_info {\n    position: absolute;\n    top: 30%;\n    z-index: 10;\n  }\n\n  .contact_info p:nth-child(2) {\n    margin: 2rem 0;\n  }\n\n  .contact_info p {\n    font-size: var(--fs-3);\n  }\n\n  .contact_info a {\n    font-size: var(--fs-3);\n  }\n\n  .contact_image {\n    height: 100vh;\n  }\n\n  .contact_info img {\n    width: 7rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React from 'react'
import "./contact.css"
import contact from "../assets/images/contact/hand.png"
import contactMobile from "../assets/images/contact/handmobi.png"
import logo from "../assets/images/logo/logo.png"
import useViewport from '../hooks/useViewport'
import ImageLazy from '../components/lazyImage'
import Logo from '../components/logo'

function Contact() {
    const viewPort = useViewport();
    const isMobile = viewPort.width < 1280;

    return (
        <div className='contact'>
            <div className="wrapper">
                <div className="container">
                    <div className="contact_info">
                        <Logo src={logo} alt="logo-contact" />
                        <p>Graphic Designer</p>
                        <a href="mailto:iskhoa99@gmail.com">Email: iskhoa99@gmail.com</a>
                        <a href="tel:090266309">Tel: 0902 663 092</a>
                    </div>
                </div>
            </div>
            <div className="contact_image">
                <ImageLazy
                    src={isMobile ? contactMobile : contact}
                    alt={contact}
                />
            </div>

        </div>
    )
}

export default Contact

import React, { useRef } from 'react'
import Logo from '../logo'
import logo from "../../assets/images/logo/logo.png"
import { NavLink, useLocation } from 'react-router-dom'
import "./style.css"

function Header() {
    const elRef = useRef()
    const location = useLocation();

    function activeLink({ isActive }) {
        return `header_menu-link${isActive ? " active" : ""}`
    }

    function handleToggleMenu() {
        elRef.current.classList.toggle('show');
    }

    return (
        <div className="wrapper" key={location.pathname}>
            <header>
                <div className="container header_inner" >
                    <Logo
                        src={logo}
                        alt={logo}
                    />

                    <nav className="header_menu">
                        <ul className='header_menu-list' ref={elRef}>
                            <li className="header_menu-item">
                                <NavLink to="/" className={activeLink} end>WORK</NavLink>
                            </li>
                            <li className="header_menu-item">
                                <NavLink to="/contact" className={activeLink}>CONTACT</NavLink>
                            </li>
                            <li className="header_menu-item">
                                <NavLink to="/about" className={activeLink} end>ABOUT</NavLink>
                            </li>
                        </ul>
                        <div className="header_menu-toggle">
                            <button onClick={handleToggleMenu} type='button'>
                                MENU
                            </button>
                        </div>
                    </nav>
                </div>
            </header>
        </div>

    )
}

export default Header

import React from "react"
import "./style.css"

import ayamnya1 from "./images/KV_color01_16.9.jpg"
import ayamnya2 from "./images/KV_color02.jpg"
import ayamnya3 from "./images/KVdraft01.jpg"
import ayamnya4 from "./images/KV_color04.jpg"
import ImageLazy from "../../lazyImage"

function Ayamnya() {
    return (
        <div className="ayamnya gap">
            <div className="item-1">
                <ImageLazy src={ayamnya1} alt="ayamnya-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={ayamnya2} alt="ayamnya-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={ayamnya3} alt="ayamnya-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={ayamnya4} alt="ayamnya-4" />
            </div>
        </div>
    )
}

export default Ayamnya

import React from "react"
import "./style.css"
import midAutumn1 from "./images/Trung-thu-2022-coverr.jpg"
import midAutumn2 from "./images/ammm-02-2.jpg"
import midAutumn3 from "./images/Be-quiet-I-ll-tell-you-hearr.jpg"
import midAutumn4 from "./images/Trung-thu-final-02.jpg"
import midAutumn5 from "./images/Trung-Thu-BOOM-SEX.jpg"
import ImageLazy from "../../lazyImage"
function MidAutumn() {
    return (
        <div className="midAutumn gap">
            <div className="item-1">
                <ImageLazy src={midAutumn1} alt="midAutumn-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={midAutumn2} alt="midAutumn-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={midAutumn3} alt="midAutumn-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={midAutumn4} alt="midAutumn-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={midAutumn5} alt="midAutumn-5" />
            </div>
        </div>
    )
}

export default MidAutumn

import React from "react"
import "./style.css"
import vincom1 from "./images/VincomTuChup.jpg"
import vincom2 from "./images/DSC01482-2.jpg"
import vincom3 from "./images/KVU04114.jpg"
import vincom4 from "./images/HAN9033-HDR.jpg"
import vincom5 from "./images/KVU04108.jpg"
import ImageLazy from "../../lazyImage"
function Vincom() {
    return (
        <div className="vincom gap">
            <div className="item-1">
                <ImageLazy src={vincom1} alt="vincom-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={vincom2} alt="vincom-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={vincom3} alt="vincom-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={vincom4} alt="vincom-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={vincom5} alt="vincom-5" />
            </div>
        </div>
    )
}

export default Vincom

import React from "react"
import "./style.css"
import greenFeed1 from "./images/EDR_6085.jpg"
import greenFeed2 from "./images/EDR_6280.jpg"
import greenFeed3 from "./images/EDR_6151.jpg"
import greenFeed4 from "./images/DJI_0316.jpg"
import greenFeed5 from "./images/imgthree.jpg"
import greenFeed6 from "./images/DJI_0186-HDR.jpg"
import greenFeed7 from "./images/imgtwo.jpg"
import greenFeed8 from "./images/EDR_5689.jpg"
import greenFeed9 from "./images/imgone.jpg"
import greenFeed10 from "./images/IMG_0095.jpg"
import greenFeed11 from "./images/DJI_0453.jpg"
import greenFeed12 from "./images/EDR_6093.jpg"
import ImageLazy from "../../lazyImage"

function GreenFeed() {
    return (
        <div className="greenFeed gap">
            <div className="item-1">
                <ImageLazy src={greenFeed1} alt="greenFeed-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={greenFeed2} alt="greenFeed-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={greenFeed3} alt="greenFeed-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={greenFeed4} alt="greenFeed-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={greenFeed5} alt="greenFeed-5" />
            </div>
            <div className="item-6">
                <ImageLazy src={greenFeed6} alt="greenFeed-6" />
            </div>
            <div className="item-7">
                <ImageLazy src={greenFeed7} alt="greenFeed-7" />
            </div>
            <div className="item-8">
                <ImageLazy src={greenFeed8} alt="greenFeed-8" />
            </div>
            <div className="item-9">
                <ImageLazy src={greenFeed9} alt="greenFeed-9" />
            </div>
            <div className="item-10">
                <ImageLazy src={greenFeed10} alt="greenFeed-10" />
            </div>
            <div className="item-11">
                <ImageLazy src={greenFeed11} alt="greenFeed-11" />
            </div>
            <div className="item-12">
                <ImageLazy src={greenFeed12} alt="greenFeed-12" />
            </div>
        </div>
    )
}

export default GreenFeed

import React from "react"
import "./style.css"
import zoneV1 from "./images/zonetwo.jpg"
import zoneV2 from "./images/zonefour.jpg"
import zoneV3 from "./images/cover_t8_03.jpg"
import zoneV4 from "./images/logo.jpg"
import zoneV5 from "./images/zoneone.jpg"
import zoneV6 from "./images/zonefive.jpg"
import zoneV7 from "./images/zonethree.jpg"
import zoneV8 from "./images/cover222.jpg"
import zoneV9 from "./images/post01.jpg"
import ImageLazy from "../../lazyImage"

function ZoneV() {
    return (
        <div className="zoneV gap">
            <div className="item-1">
                <ImageLazy src={zoneV1} alt="zoneV1" />
            </div>
            <div className="item-2">
                <ImageLazy src={zoneV2} alt="zoneV2" />
            </div>
            <div className="item-3">
                <ImageLazy src={zoneV3} alt="zoneV3" />
            </div>
            <div className="item-4">
                <ImageLazy src={zoneV4} alt="zoneV4" />
            </div>

            <div className="item-5">
                <ImageLazy src={zoneV5} alt="zoneV5" />
            </div>
            <div className="item-6">
                <ImageLazy src={zoneV6} alt="zoneV6" />
            </div>
            <div className="item-7">
                <ImageLazy src={zoneV7} alt="zoneV7" />
            </div>
            <div className="item-8">
                <ImageLazy src={zoneV8} alt="zoneV8" />
            </div>
            <div className="item-9">
                <ImageLazy src={zoneV9} alt="zoneV9" />
            </div>
        </div>
    )
}

export default ZoneV

import React from "react"
import "./style.css"
import vodka1 from "./images/mockup22.jpg"
import vodka2 from "./images/Free_Flyer_Mockup_5.jpg"
import vodka3 from "./images/leaflet_f_011.jpg"
import vodka4 from "./images/leaflet_f_01.jpg"
import ImageLazy from "../../lazyImage"
function Vodka() {
    return (
        <div className="vodka gap">
            <div className="item-1">
                <ImageLazy src={vodka1} alt="vodka-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={vodka2} alt="vodka-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={vodka3} alt="vodka-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={vodka4} alt="vodka-4" />
            </div>
        </div>
    )
}

export default Vodka

import React from "react"
import "./style.css"
import lacalut1 from "./images/Skull2.jpg"
import lacalut2 from "./images/Layer-10.jpg"
import lacalut3 from "./images/Layer-31.jpg"
import lacalut4 from "./images/stock-photo-human-skull-isolated-1378648106.jpg"
import lacalut5 from "./images/stock-photo-old-bones-of-the-human-hand-isolated-on-white-background-160815224.jpg"
import lacalut6 from "./images/stock-photo-skeleton-arm-and-hand-on-a-white-background-378079033.jpg"
import lacalut7 from "./images/BG-stock.jpg"
import ImageLazy from "../../lazyImage"
function Lacalut() {
    return (
        <div className="lacalut gap">
            <div className="item-1">
                <ImageLazy src={lacalut1} alt="lacalut1" />
            </div>
            <div className="item-2">
                <ImageLazy src={lacalut2} alt="lacalut2" />
            </div>
            <div className="item-3">
                <ImageLazy src={lacalut3} alt="lacalut3" />
            </div>
            <div className="item-4">
                <ImageLazy src={lacalut4} alt="lacalut4" />
            </div>
            <div className="item-5">
                <ImageLazy src={lacalut5} alt="lacalut5" />
            </div>
            <div className="item-6">
                <ImageLazy src={lacalut6} alt="lacalut6" />
            </div>
            <div className="item-7">
                <ImageLazy src={lacalut7} alt="lacalut7" />
            </div>
        </div>
    )
}

export default Lacalut

import React from "react"
import "./style.css"
import PLT1 from "./images/Backprop_text.jpg"
import PLT2 from "./images/mockup_vuong07.jpg"
import PLT3 from "./images/FA_FLYER.jpg"
import PLT4 from "./images/Group-11.jpg"
import PLT5 from "./images/Free_Hanging_Tote_Bag_Mockup_3.jpg"
import ImageLazy from "../../lazyImage"
function PLT() {
    return (
        <div className="PLT gap">
            <div className="item-1">
                <ImageLazy src={PLT1} alt="PLT-1" />
            </div>
            <div className="item-2">
                <ImageLazy src={PLT2} alt="PLT-2" />
            </div>
            <div className="item-3">
                <ImageLazy src={PLT3} alt="PLT-3" />
            </div>
            <div className="item-4">
                <ImageLazy src={PLT4} alt="PLT-4" />
            </div>
            <div className="item-5">
                <ImageLazy src={PLT5} alt="PLT-5" />
            </div>
        </div>
    )
}

export default PLT

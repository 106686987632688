// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vodka {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.vodka .item-1 {
  grid-column: 1/3;
}
.vodka .item-2 {
  grid-column: 1/3;
}
.vodka .item-3 {
  grid-column: 1/2;
}
.vodka .item-4 {
  grid-column: 2/3;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/Vodka/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".vodka {\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n}\n\n.vodka .item-1 {\n  grid-column: 1/3;\n}\n.vodka .item-2 {\n  grid-column: 1/3;\n}\n.vodka .item-3 {\n  grid-column: 1/2;\n}\n.vodka .item-4 {\n  grid-column: 2/3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

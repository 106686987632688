// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  padding-left: 1.8rem;
}
.logo img {
  width: 8.5rem;
}

@media screen and (min-width: 1280px) {
  .logo {
    padding-left: 0;
  }

  .logo img {
    width: 7rem;
    padding-bottom: 0.6rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/logo/style.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,sBAAsB;EACxB;AACF","sourcesContent":[".logo {\n  padding-left: 1.8rem;\n}\n.logo img {\n  width: 8.5rem;\n}\n\n@media screen and (min-width: 1280px) {\n  .logo {\n    padding-left: 0;\n  }\n\n  .logo img {\n    width: 7rem;\n    padding-bottom: 0.6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { Link } from 'react-router-dom'
import ImageLazy from '../lazyImage'
import "./style.css"

function Logo({ src, alt }) {
    return (
        <div className="logo">
            <Link to="/">
                <ImageLazy
                    src={src}
                    alt={alt}
                />
            </Link>
        </div>
    )
}

export default Logo

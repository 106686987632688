// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ayamnya {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.ayamnya .item-1 {
  grid-column: 1/4;
}
.ayamnya .item-2 {
  grid-column: 1/2;
}
.ayamnya .item-3 {
  grid-column: 2/3;
  grid-row: 2/3;
}
.ayamnya .item-4 {
  grid-column: 3/4;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/Ayamnya/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".ayamnya {\n  display: grid;\n  grid-template-columns: repeat(3, 1fr);\n}\n\n.ayamnya .item-1 {\n  grid-column: 1/4;\n}\n.ayamnya .item-2 {\n  grid-column: 1/2;\n}\n.ayamnya .item-3 {\n  grid-column: 2/3;\n  grid-row: 2/3;\n}\n.ayamnya .item-4 {\n  grid-column: 3/4;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect, useState } from "react";
import "./work.css"
import { data } from "../data/data"
import { formatSlug } from "../utils/formatSlug";
import ImageLazy from "../components/lazyImage";
import WorkDetail from "./WorkDetail";
import useViewport from "../hooks/useViewport";
import imgPlaceholder from "../assets/images/300.png"
import { trackWindowScroll } from 'react-lazy-load-image-component';

function Work() {

    const viewPort = useViewport();
    const isMobile = viewPort.width < 1280;

    const [showModal, setShowModal] = useState(false)
    const [workSelected, setWorkSelected] = useState(null)

    useEffect(function () {
        function handler() {
            setWorkSelected(null)
            setShowModal(false)
        }

        window.addEventListener("popstate", handler)

        return function () {
            window.removeEventListener("popstate", handler)
        }
    }, [])

    function handleClick(item) {
        setWorkSelected(item)
        setShowModal(true)
        window.history.pushState({}, "", `/work/${formatSlug(item.title)}`)
    }

    function renderCards(arr) {
        return (
            <div className="work_list gap">
                {
                    arr.map((item, index) => (
                        <div onClick={handleClick.bind(null, item)} className={`work_list-item work_list-item-${index + 1}`} key={item.id}>
                            <div className="work_list-item-image">
                                <ImageLazy
                                    placeholder={<img alt='404' src={imgPlaceholder} />}
                                    placeholderSrc={imgPlaceholder}
                                    src={item.image}
                                    alt={item.title.join(" ")}
                                />
                            </div>
                            <div className="work_list-item-overlay"></div>
                            <div className="work_list-item-text">
                                {
                                    item.title.map((t, i) => (
                                        <h4 key={i}>{t}</h4>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        )
    }

    return (
        <>
            {
                (!isMobile || showModal === false) &&
                <div className="wrapper">
                    <div className="container work">
                        {renderCards(data.slice(0, 6))}
                        {renderCards(data.slice(6, 12))}
                        {renderCards(data.slice(12, 18))}
                    </div>
                </div>
            }
            {showModal && <WorkDetail item={workSelected} />}
        </>
    )
}
export default trackWindowScroll(Work)

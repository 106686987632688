// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../nodevenv/website/18/lib/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PLT {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}

.PLT .item-1 {
  grid-column: 1/5;
  grid-row: 1/4;
}
.PLT .item-2 {
  grid-column: 5/7;
  grid-row: 1/2;
}
.PLT .item-3 {
  grid-column: 5/7;
  grid-row: 2/5;
}
.PLT .item-4 {
  grid-column: 1/5;
  grid-row: 4/6;
}
.PLT .item-5 {
  grid-column: 5/7;
  grid-row: 5/6;
}
`, "",{"version":3,"sources":["webpack://./src/components/details/PLT/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,qCAAqC;AACvC;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;EACE,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".PLT {\n  display: grid;\n  grid-template-columns: repeat(6, 1fr);\n}\n\n.PLT .item-1 {\n  grid-column: 1/5;\n  grid-row: 1/4;\n}\n.PLT .item-2 {\n  grid-column: 5/7;\n  grid-row: 1/2;\n}\n.PLT .item-3 {\n  grid-column: 5/7;\n  grid-row: 2/5;\n}\n.PLT .item-4 {\n  grid-column: 1/5;\n  grid-row: 4/6;\n}\n.PLT .item-5 {\n  grid-column: 5/7;\n  grid-row: 5/6;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from 'react'
import "./workDetail.css"
import { data } from "../data/data"
import { Link, useNavigate, useParams } from 'react-router-dom'
import useViewport from '../hooks/useViewport'
import { formatSlug } from '../utils/formatSlug'
import slugify from 'slugify'
import { trackWindowScroll } from 'react-lazy-load-image-component';
import NotFound from './NotFound'

function WorkDetail({ item }) {
    const navigate = useNavigate()
    const params = useParams()
    const slug = item ? formatSlug(item.title) : params.slug

    const viewPort = useViewport();
    const isMobile = viewPort.width < 1280;

    useEffect(function () {
        if (!isMobile) {
            document.body.classList.add("overflow-hidden")
            document.body.style.marginLeft = "-6px"
        }

        return function () {
            if (!isMobile) {
                document.body.classList.remove("overflow-hidden")
                document.body.style.marginLeft = "0"
            }
        }
    })

    useEffect(function () {
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, [])

    function handleClose() {
        if (item) {
            window.history.back()
        } else {
            navigate("/")
        }
    }

    function handleCloseBackdrop(e) {
        if (!isMobile && e.target.classList.contains(slug)) {
            handleClose()
        }
    }

    function renderDetailContent() {
        const item = data.find(d => formatSlug(d.title) === slug)

        if (item) {
            const { insight: { title, description, client, comp, next, slugNext } } = item
            return (
                <>
                    <div className="work_detail-desc">
                        <div className="work_detail-desc-top">
                            {
                                title.map((t, i) => (
                                    <h4 key={i}>{t}</h4>
                                ))
                            }
                        </div>

                        <div className="work_detail-desc-middle">
                            <p>{description}</p>
                        </div>

                        <div className="work_detail-desc-bottom">
                            <p>{client}</p>
                        </div>
                    </div>

                    <div className="work_detail-images">
                        {comp}
                    </div>

                    <Link to={`/work/${slugify(slugNext).toLowerCase()}`} replace className='work-next'>next:
                        <span> {next}</span>
                    </Link>
                </>
            )
        } else {
            return <NotFound isRevert />
        }
    }

    return (
        <div className="wrapper">
            <div key={slug} onClick={handleCloseBackdrop} className={`fadeIn work_detail ${slug}`}>
                <div className="container" style={{ padding: `${isMobile ? "0 0" : ""}` }}>
                    <div className='work_detail-close'>
                        <button onClick={handleClose} type='button'>
                            <i className="bi bi-x-lg"></i>
                        </button>
                    </div>
                    <div className="work_detail-content">
                        {renderDetailContent()}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default trackWindowScroll(WorkDetail)
